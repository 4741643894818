import _ from 'lodash';
import { DataService } from '../dataService/dataService';
import dayjs from 'dayjs';
import Web3 from 'web3';
import { i18n } from '@/main';
import { debounce } from 'lodash';
const { t } = i18n.global;

const validateRequired = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.reject(t('validate.required', { label: t(label) }));
  }
  return Promise.resolve();
};

export const required = (label) => {
  return {
    validator: (rule, value) => validateRequired(rule, value, label),
    trigger: ['change', 'blur'],
    whitespace: true,
    required: true,
  };
};

const validateMaxlength = async (_rule, value, maxlength, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if ((value + '').length > maxlength) {
    return Promise.reject(
      t('validate.max_length', { label: t(label), maxlength })
    );
  }
  return Promise.resolve();
};

export const maxlength = (maxlength, label) => {
  return {
    validator: (rule, value) =>
      validateMaxlength(rule, value, maxlength, label),
    trigger: ['change', 'blur'],
  };
};

const validateMinlength = async (_rule, value, minlength, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if ((value + '').length < minlength) {
    return Promise.reject(
      t('validate.min_length', { label: t(label), minlength })
    );
  }
  return Promise.resolve();
};

export const minlength = (minlength, label) => {
  return {
    validator: (rule, value) =>
      validateMinlength(rule, value, minlength, label),
    trigger: ['change', 'blur'],
  };
};

const callApi = async (rule, value, label, url, params) => {
    if (_.isNil(value) || (value + '').trim() === '') {
      return Promise.resolve();
    }
  
    try {
      const response = await DataService.get(url, {
        ...params,
        [rule.field]: value,
      });
      if( response.code === '400'){
        return Promise.reject(t('validate.existed', { label: t(label) }));
      }
      Promise.resolve()
      // return response.code === '200'
      //   ? Promise.resolve()
      //   : Promise.reject(t('validate.existed', { label: t(label) }));
    } catch(error) {
        return Promise.reject(t('validate.existed', { label: t(label) })); 
    }
  };
  
  export const asyncExistedRule = (label, url, params) => {
    const debouncedCallApi = debounce(callApi, 500);
  
    return {
      validator: async (rule, value) => {
        try {
          await debouncedCallApi(rule, value, label, url, params);
        } catch (error) {
          throw new Error(error);
        }
      },
      trigger: ['change', 'blur'],
    };
  };
const validateEmail = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
      value + ''
    )
  ) {
    return Promise.resolve();
  }
  return Promise.reject(t('validate.malformed', { label: t(label) }));
};

export const emailRule = (label = 'Email') => {
  return {
    validator: (rule, value) => validateEmail(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

const validateNumber = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (/^\d+$/.test(value + '')) {
    return Promise.resolve();
  }
  return Promise.reject(t('validate.only_number', { label: t(label) }));
};

export const numberRule = (label) => {
  return {
    validator: (rule, value) => validateNumber(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

const validateMin = async (_rule, value, label, min) => {
  if (!/^[0-9]+([\\,\\.][0-9]+)?$/.test(value) || parseFloat(value) > min) {
    return Promise.resolve();
  }
  return Promise.reject(`${label} must greater than ${min}`);
};

export const minRule = (label, min) => {
  return {
    validator: (rule, value) => validateMin(rule, value, label, min),
    trigger: ['change', 'blur'],
  };
};

export const patternRule = (pattern, label) => {
  return {
    pattern: pattern,
    message: t('validate.malformed', { label: t(label) }),
    trigger: ['change', 'blur'],
  };
};

export const patternRuleMessage = (pattern, msg, maxlength, minlength) => {
  return {
    validator: (rule, value) =>
      validatePattern(rule, value, msg, pattern, maxlength, minlength),
    trigger: ['change', 'blur'],
  };
};

const validatePattern = async (
  _rule,
  value,
  msg,
  pattern,
  maxlength,
  minlength
) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if ((value + '').length > maxlength) {
    return Promise.reject(msg);
  }
  if ((value + '').length < minlength) {
    return Promise.reject(msg);
  }
  if (pattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(msg);
};

export const rangeDatePickerRule = (label) => {
  return {
    validator: (rule, value) => rangeDatePicker(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

export const rangeDatePicker = async (_rule, value, label) => {
  if (!value && value.length < 2) {
    return Promise.resolve();
  }
  const fromDate = dayjs(value[0]).endOf('day');
  const toDate = dayjs(value[1]).endOf('day');
  const check = toDate.diff(fromDate, 'days') > 30;
  if (check) return Promise.reject(`${label} no more than 30 days`);
  return Promise.resolve();
};

const connectWeb3 = async (_rule, value, label, url) => {
  if (_.isNil(url) || (url + '').trim() === '' || _.isNil(value)) {
    return Promise.resolve();
  }

  try {
    const web3 = new Web3(url);
    const chainId = await web3.eth.getChainId();
    if (value !== chainId) {
      return Promise.reject(`The RPC URL you have entered returned a different chain ID (${chainId}). 
                Please update the Chain ID to match the RPC URL of the network you are trying to add.`);
    }
    return Promise.resolve();
  } catch {
    return Promise.reject(`Could not fetch chain ID. Is your RPC URL correct?`);
  }
};

export const asyncConnectWeb3Rule = (label, url) => {
  return {
    validator: (rule, value) => connectWeb3(rule, value, label, url),
    trigger: ['change', 'blur'],
  };
};

const validatePassword = async (_rule, value, form) => {
  const password = form.password;
  if (
    _.isNil(value) ||
    (value + '').trim() === '' ||
    _.isNil(password) ||
    (password + '').trim() === ''
  ) {
    return Promise.resolve();
  }
  if (value !== password) {
    return Promise.reject(t('validate.password_confirm'));
  }
  return Promise.resolve();
};

export const confirmPassword = (form) => {
  return {
    validator: (rule, value) => validatePassword(rule, value, form),
    trigger: ['change', 'blur'],
  };
};

const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,50}$/;

const validatePasswordRule = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (PASSWORD_REGEX.test(value + '')) {
    return Promise.resolve();
  }
  return Promise.reject(`${label} ${t('validate.password_regex')}`);
};

export const passwordRule = (label = t('user.password')) => {
  return {
    validator: (rule, value) => validatePasswordRule(rule, value, label),
    trigger: ['change', 'blur'],
  };
};

const validatePhoneNumber = async (_rule, value, label) => {
  if (_.isNil(value) || (value + '').trim() === '') {
    return Promise.resolve();
  }
  if (/^\+?\d+$/.test(value + '')) {
    return Promise.resolve();
  }
  return Promise.reject(t('validate.malformed', { label: t(label) }));
};

export const phoneNumberRule = (label) => {
  return {
    validator: (rule, value) => validatePhoneNumber(rule, value, label),
    trigger: ['change', 'blur'],
  };
};
